import ReactOnRails from 'react-on-rails';

import H5pComponent from '../bundles/H5p/App';
import InteractiveVideo from '../bundles/InteractiveVideo';
import EditorVideo from '../bundles/Editor';
import StudentResultChart from '../bundles/chart';
import ButtonShare from '../bundles/components/Share';
import UploadMultipleFile from '../bundles/components/UploadMultipleFile';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  H5pComponent,
  InteractiveVideo,
  EditorVideo,
  StudentResultChart,
  ButtonShare,
  UploadMultipleFile,
});
