import React from 'react';
import Chart from 'react-apexcharts';

const StudentResultChart = props => {
  const params = {
    options: {
      chart: {
        id: 'student-result-chart',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          distributed: true,
        },
      },
      legend: {
        show: false,
        fontSize: '20px',
        fontFamily: 'Helvetica, Arial',
        fontWeight: 400,
      },
      xaxis: {
        categories: ['First', 'Second', 'Third'],
        tooltip: {
          enabled: false
        },
        labels: {
          style: {
            fontSize: '15px'
          },
        },
        title: {
          text: 'Number of tries to get the correct answers',
          style: {
            fontSize: '15px',
            fontWeight: 550,
            cssClass: 'apexcharts-xaxis-label',
          },
        }
      },
      yaxis: {
        title: {
          text: 'Number of answers',
          style: {
            fontSize: '15px',
            fontWeight: 550,
            cssClass: 'apexcharts-xaxis-label',
          },
        },
        labels: {
          formatter(val) {
            return val.toFixed(0);
          },
          style: {
            fontSize: '15px'
          },
        }
      },
      tooltip: {
        enabled: false,
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '18px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 'bold',
        },
      },
      colors : ['#088a8b', '#fa744e', '#ff5b5b'],
    },
    series: [
      {
        data: props.results
      }
    ]
  }

  return(
    <Chart options={params.options} series={params.series} type="bar" width={500} height={400} />
  )
}

export default StudentResultChart;
