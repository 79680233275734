import React, { useState } from 'react';
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon,
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ButtonShare = (props) => {
  const [copyStatus, setCopyStatus] = useState<boolean>(false);
  const shareLink = props.url;
  const title = 'Come join the course with me!';

  return (
    <div className="d-flex">
      <div className="network">
        <CopyToClipboard text={shareLink} onCopy={() => setCopyStatus(true)}>
          <button
            className="button-copy"
            data-toggle="tooltip"
            title="Copied to clipboard"
            data-bs-trigger="click"
          >
            <i className="fas fa-copy"></i>
          </button>
        </CopyToClipboard>
      </div>
      <div className="network">
        <FacebookShareButton
          url={shareLink}
          quote={title}
          className="network__share-button"
        >
          <FacebookIcon size={'2rem'} round />
        </FacebookShareButton>
      </div>
      <div className="network">
        <FacebookMessengerShareButton appId={props.fbID} url={shareLink}>
          <FacebookMessengerIcon size={'2rem'} round />
        </FacebookMessengerShareButton>
      </div>
      <div className="network">
        <TwitterShareButton
          url={shareLink}
          title={title}
          className="network__share-button"
        >
          <TwitterIcon size={'2rem'} round />
        </TwitterShareButton>
      </div>
      <div className="network">
        <WhatsappShareButton
          url={shareLink}
          title={title}
          separator=": "
          className="network__share-button"
        >
          <WhatsappIcon size={'2rem'} round />
        </WhatsappShareButton>
      </div>

      <div className="network">
        <LinkedinShareButton
          url={shareLink}
          title={title}
          className="network__share-button"
        >
          <LinkedinIcon size={'2rem'} round />
        </LinkedinShareButton>
      </div>

      <div className="network">
        <EmailShareButton
          url={shareLink}
          subject={title}
          body="body"
          className="network__share-button"
        >
          <EmailIcon size={'2rem'} round />
        </EmailShareButton>
      </div>
    </div>
  );
};

export default ButtonShare;
