import React, { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import ReactOnRails from 'react-on-rails';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import UploadIcon from '../../../images/icon/upload.svg';
import PDFFile from '../../../images/icon/file-pdf-solid.svg';

axios.defaults.headers.common['X-CSRF-TOKEN'] =
  ReactOnRails.authenticityToken();
axios.defaults.headers.common.Accept = 'application/json';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const img = {
  width: 50,
  height: 50,
  boderRadius: 10,
  boder: '1px; solid black',
};

const UploadMultipleFile = (props) => {
  const [files, setFiles] = useState(
    props.documents.length > 0 ? props.documents : [],
  );
  const [loading, setLoading] = useState(false);
  const [variant, setVariant] = useState('danger');
  const [message, setMessage] = useState('');
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    onDrop: useCallback((acceptedFiles) => {
      const formData = new FormData();
      acceptedFiles.forEach((file) => {
        formData.append('files[]', file);
      });
      setLoading(true);
      axios({
        method: 'post',
        url: props.urlUpload,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setFiles(res.data.documents);
          setVariant('success');
          setMessage(res.data.message);
          setTimeout(() => {
            setMessage('');
          }, 3000);
        } else {
          setVariant('danger');
          setMessage("Oh dear. Something's wrong.");
          setTimeout(() => {
            setMessage('');
          }, 3000);
        }
      });
    }, []),
    maxFiles: 4,
    maxSize: 25000000,
  });

  const removeFile = (file) => {
    axios({
      method: 'post',
      url: props.urlDestroy,
      data: { name: file.name },
    }).then((res) => {
      if (res.status === 200) {
        const index = files.indexOf(file);
        if (index !== -1) {
          files.splice(index, 1);
          setFiles([...files]);
        }
        setVariant('success');
        setMessage(res.data.message);
        setTimeout(() => {
          setMessage('');
        }, 3000);
      } else {
        setVariant('danger');
        setMessage("Oh dear. Something's wrong.");
        setTimeout(() => {
          setMessage('');
        }, 3000);
      }
    });
  };

  const bytesToMegaBytes = (bytes) => (bytes / (1024 * 1024)).toFixed(2);

  const thumbs = files.map((file) => (
    <div
      style={{
        backgroundColor: '#F0F0F0',
        boder: '1px solid black',
        boderRadius: '10px',
      }}
      key={file.name}
      className="col-12 d-inline-flex p-2 mb-3 align-items-center"
    >
      <img
        src={file.name.split('.').pop() === 'pdf' ? PDFFile : file.preview}
        style={img}
        className="col-2 me-2"
        // Revoke data uri after image is loaded
        onLoad={() => {
          URL.revokeObjectURL(file.preview);
        }}
      />
      <div className="col-9 me-2 ">
        <p style={{ textAlign: 'left' }} className="text-break mb-0">
          {file.name}
        </p>
        <p style={{ textAlign: 'left' }} className="text-break mb-0">
          {bytesToMegaBytes(file.size)}MB
        </p>
      </div>
      <div className="col-1">
        <button
          type="button"
          className="border-0 text-right h-50 text-right"
          style={{ fontSize: '20px' }}
          onClick={() => {
            removeFile(file);
          }}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>
      </div>
    </div>
  ));

  useEffect(
    () =>
      // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
      () =>
        files.forEach((file) => URL.revokeObjectURL(file.preview)),
    [],
  );

  return (
    <>
      {message && (
        <Alert
          style={{ position: 'fixed', top: 20, right: 20 }}
          variant={variant}
        >
          {message}
        </Alert>
      )}

      <section className="w-100 text-center">
        <label className="w-100 d-block btn-shadow btn-shadow--white w-100 h-auto p-3">
          <div
            {...getRootProps({
              className: 'dropzone',
              id: 'teacher-dropzone',
              onClick: (evt) => evt.preventDefault(),
            })}
          >
            <input id="input-teacher-documents" {...getInputProps({})} />
            <img className="pb-3 pt-4" src={UploadIcon} />
            <p className="fw-bold">Click or drag file to this area to upload</p>
            <p>Only PDF files with max size of 15MB</p>
          </div>
          {loading ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <div className={loading ? 'd-none' : ''} style={thumbsContainer}>
              {thumbs}
            </div>
          )}
        </label>
      </section>
    </>
  );
};

export default UploadMultipleFile;
